<template>
    <div class="d-flex d-flex-row align-items-center">
        <h2 class="h3 no-margin flex-grow">{{ tittel }}</h2>
        <router-link
            :to="{
                name: routes.editTema,
                params: { id: id }
            }"
            tag="button"
            class="btn btn--primary btn--edit"
        >
            <span class="icon icon--edit--white"></span>
            Rediger tema
        </router-link>
    </div>
</template>

<script>
    export default {
        props: {
            id: String,
            tittel: String
        }
    };
</script>
