<template>
    <div class="page">
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                        <h1 class="mb-70">Rediger temaer</h1>
                        <ul class="row list--unstyled">
                            <li
                                class="col-12"
                                v-for="tema in temaer"
                                :key="tema.id"
                            >
                                <EditTemaListItem
                                    :id="tema.id"
                                    :tittel="tema.tittel"
                                />
                                <hr />
                            </li>
                        </ul>
                        <div>
                            <router-link
                                :to="{ name: routes.createTema }"
                                tag="button"
                                class="btn btn--primary btn--add btn--lg"
                            >
                                <span class="icon icon--add--white"></span>
                                Legg til nytt tema
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../../scripts/api';
    import EditTemaListItem from '../../components/List/EditTemaListItem';

    export default {
        components: {
            EditTemaListItem
        },
        data() {
            return {
                temaer: []
            };
        },
        beforeMount() {
            api.getTemaer().then(res => {
                this.temaer = res;
            });
        }
    };
</script>
